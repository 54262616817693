<template>
    <div class="login-card card w-100 p-0">
        <div :style="'padding-top:' + insets.top + 'px'">
            <div class="header position-fixed background-white d-flex align-items-center justify-content-between pr-3 pl-3">
                <div @click.prevent="openCrashReport" class="chat-icon d-block d-md-none background-white rounded-circle d-flex align-items-center justify-content-center header-icon-container app-only">
                    <img alt="info-chat" src="~/assets/images/icons/info-chat.svg" width="18px">
                </div>
                <span class="logo text-vanitas font-size-xl text-brand text-uppercase">{{ $t('Confestetica')}}</span>
                <div @click.prevent="$router.push('/menu')" class="chat-icon d-block d-lg-none background-white rounded-circle d-flex align-items-center justify-content-center header-icon-container">
                    <img alt="menu" src="~/assets/images/icons/menu.svg" width="18px">
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <img alt="banner" src="~/assets/images/banner.png" class="w-100">
            <div class="font-size-base px-3 mt-3">
                {{ $t('Dal') }} <nuxt-link to="/chi-siamo/atto-costitutivo">2007</nuxt-link> {{ $t('Confestetica è l\'associazione nazionale maggiormente rappresentativa dell\'estetista con') }} <nuxt-link to="/centri-estetici"><br />{{ (users.usersCount + 9000).toLocaleString()}}</nuxt-link> {{ $t('estetiste iscritte e certificate e') }} <nuxt-link to="/centri-estetici"><br />{{ (accounts.accountsCount).toLocaleString()}}</nuxt-link> {{ $t('centri estetici.')}}<br/>
            </div>
            <div class="px-3">
<!--                <div v-if="!app">
                    <v-facebook-login
                            :app-id="facebookAppId"
                            class="mt-3 w-100 text-center d-block d-lg-none"
                            :token-style="{'margin': 0}"
                            :login-options="{scope: 'email,user_gender,user_birthday'}"
                            @login="facebookLogin"
                    >
                        <template slot="login">
                            {{ $t('Accedi con Facebook') }}
                        </template>
                    </v-facebook-login>
                </div>
                <a href="#" class="btn btn-facebook w-100 mt-2" v-if="app" @click.prevent="loginWithNativeFacebook"><i class="fa fa-facebook mr-2"></i> Accedi con facebook</a>-->
            </div>
            <form @submit.prevent="submit" class="flex-grow-1 p-3">
                <div class="form-group">
                    <label class="sr-only">E-mail</label>
                    <input v-avoid-keyboard type="text" autocomplete="off" name="email" class="form-control from-control-flat background-gray" placeholder="E-mail" v-model="email" autocapitalize="none" />
                </div>
                <div class="form-group">
                    <label class="sr-only">Password</label>
                    <input v-avoid-keyboard v-input-password type="password" name="password" class="form-control from-control-flat background-gray" placeholder="Password" v-model="password" />
                </div>
                <div>
                    <button @click.prevent="submit" class="btn btn-brand mt-3 mb-1 w-100" :disabled="processing">
                        <span v-if="!processing">{{ $t('Accedi') }}</span>
                        <span v-else><i class="fa fa-spinner fa-spin"></i> {{ $t('Accesso in corso...')}}</span>
                    </button>
                </div>
                <small>
                    <nuxt-link to="/password-reset" class="text-brand"><u>{{ $t('Password dimenticata?')}}</u></nuxt-link>
                </small>
            </form>
        </div>
        <div class="card-footer login-footer border-0 d-flex flex-column justify-content-center" v-if="!keyboardOpened" :style=" insets.bottom ? 'padding-bottom:' + insets.bottom + 'px!important' : ''">
            <div class="d-flex justify-content-center align-items-center mb-3 font-size-xs font-weight-bold">
                <div class="divider mr-3"></div>
                {{ $t('OPPURE')}}
                <div class="divider ml-3"></div>
            </div>
            <a href="#" @click.prevent="setStep('intro')" class="btn btn-brand btn-transparent w-100">
                {{ $t('Registrati') }}
            </a>
        </div>
    </div>
</template>

<script>

    import { mapActions, mapState, mapMutations } from 'vuex'
    import HeaderBar from '~/components/HeaderBar'

    export default {

        components: {
          HeaderBar
        },

        data() {
            return {
                email: '',
                password: '',
                processing: false,
                facebookAppId: process.env.FACEBOOK_CLIENT_ID
            }
        },

        computed: {
            ...mapState([
                'insets',
                'accounts',
                'users',
                'keyboardOpened',
                'register',
                'mobileMode',
                'app'
            ]),
        },

        methods: {
            ...mapMutations({
                'setReferrerCode': 'register/SET_REFERRER_CODE'
            }),
            ...mapActions({
                'login': 'user/login',
                'setStep': 'register/setStep',
                'openCrashReport': 'openCrashReport'
            }),
            facebookLogin(payload) {

                let token = payload && payload.authResponse ? payload.authResponse.accessToken : null

                if (token) {
                    this.facebookLoginWithToken(token)
                }
            },
            facebookLoginWithToken(token) {
                this.$nuxt.$loading.start()
                this.$axios.post('/api/login-social', {
                    social: 'facebook',
                    token: token,
                    referrer_code: this.register.data.referrer_code,
                    registered_from: this.register.data.registered_from,
                })
                    .then((response) => {
                        this.$nuxt.$loading.finish()
                        return this.$auth.setUserToken(response.data.access_token)
                            .then(() => {
                                this.$router.push('/dashboard');
                            })
                    }).finally(() => {
                    this.$nuxt.$loading.finish()
                })
            },
            loginWithNativeFacebook() {

                let event = { event: 'native-facebook-login' }

                if(window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify(event));
                } else {
                    window.postMessage(JSON.stringify(event), '*');
                }
            },
            submit() {

                this.processing = true;

                this.login({
                    username: this.email,
                    password: this.password
                })
                    .then(() => {
                        this.$router.push('/dashboard');
                        this.processing = false;
                    })
                    .catch(() => {

                        this.$notify({
                            group: 'messages',
                            type: 'error',
                            title: this.$t('Le credenziali inserite non risultano essere corrette. Si prega di riprovare.')
                        });

                        this.$router.push('/')

                        this.processing = false;
                    })
            }
        },

        created () {

            if (this.$route.query.ref) {
                this.setReferrerCode(this.$route.query.ref)
            }
            this.$nuxt.$on('login-with-facebook-token', (token) => {
                this.facebookLoginWithToken(token)
            })
        }
    }
</script>

<style lang="scss" scoped>
    .card {
        border: none;
        z-index: 1000;
        border-radius: 0;
        padding: 0;
        /*position: fixed!important;*/
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        top: 0;

        .login-footer {

            .divider {
                width: 25%;
                border: 1px solid #8C8B8C;
            }
        }
    }
</style>
